import { __decorate } from "tslib";
import { songStore } from '@/store';
import { Component, Vue, Watch } from 'vue-property-decorator';
let FooterAudio = class FooterAudio extends Vue {
    constructor() {
        super(...arguments);
        this.isTrackTimeCurrent = null;
        this.isTrackTimeTotal = null;
        this.range = 0;
        this.seekerContainer = null;
        this.playbackSpeed = [
            { number: 0.5 },
            { number: 1 },
            { number: 1.5 },
            { number: 2 },
        ];
    }
    get currentTrack() {
        return songStore.currentTrack;
    }
    get isPlaying() {
        return songStore.isPlaying;
    }
    get audio() {
        return songStore.audio;
    }
    playOrPauseThisSong(currentTrack) {
        songStore.playOrPauseThisSong(currentTrack);
    }
    restartAudio() {
        this.audio.currentTime = 0;
    }
    close() {
        songStore.resetState();
    }
    handlePlaybackSpeed(item) {
        this.audio.playbackRate = item.number;
    }
    timeUpdate() {
        this.audio.addEventListener('timeupdate', () => {
            const minutes = Math.floor(this.audio.currentTime / 60);
            const seconds = Math.floor(this.audio.currentTime - minutes * 60);
            this.isTrackTimeCurrent =
                minutes + ':' + seconds.toString().padStart(2, '0');
            const value = (100 / this.audio.duration) * this.audio.currentTime;
            this.range = value;
            if (this.audio.currentTime >= this.audio.duration) {
                songStore.resetState();
            }
        });
    }
    loadmetadata() {
        this.audio.addEventListener('loadedmetadata', () => {
            const duration = this.audio.duration;
            const minutes = Math.floor(duration / 60);
            const seconds = Math.floor(duration % 60);
            this.isTrackTimeTotal =
                minutes + ':' + seconds.toString().padStart(2, '0');
        });
    }
    onAudio() {
        this.timeUpdate();
        this.loadmetadata();
    }
    onChange(newVal) {
        if (newVal) {
            this.seekerContainer = this.$refs.seekerContainer;
            if (this.seekerContainer) {
                this.seekerContainer.addEventListener('click', this.handleSeekerClick);
            }
        }
    }
    handleSeekerClick(event) {
        const clickPosition = (event.pageX - this.seekerContainer.offsetLeft) /
            this.seekerContainer.offsetWidth;
        const time = this.audio.duration * clickPosition;
        this.audio.currentTime = time;
    }
    mounted() {
        if (this.audio) {
            setTimeout(() => {
                this.timeUpdate();
                this.loadmetadata();
            }, 300);
        }
    }
};
__decorate([
    Watch('audio')
], FooterAudio.prototype, "onAudio", null);
__decorate([
    Watch('currentTrack')
], FooterAudio.prototype, "onChange", null);
FooterAudio = __decorate([
    Component({
        components: {},
    })
], FooterAudio);
export default FooterAudio;
