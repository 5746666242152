import { __decorate } from "tslib";
import FooterAudio from '@/components/FooterAudio.vue';
import { livroStore } from '@/utils/store-accessor';
import { Component, Vue, Prop } from 'vue-property-decorator';
// @ts-ignore
import glossario from './glossario.json';
const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
let Glossary = class Glossary extends Vue {
    constructor() {
        super(...arguments);
        this.tipoGlossario = 1;
        this.isActive = false;
        this.itemAudio = {};
        this.timeOut = 2000;
        this.isText = '';
        this.showSnackBar = false;
        this.isCorrect = null;
        this.listenCondition = false;
        this.listen = '';
        this.searchWords = '';
        this.recognition = SpeechRecognition ? new SpeechRecognition() : null;
        this.isListening = false;
        this.isMicAudio = {};
        this.idiomas = {
            Inglês: 'en-US',
            Francês: 'fr-FR',
            Espanhol: 'es-ES',
            Italiano: 'it-IT',
            Alemão: 'de-DE',
        };
    }
    get items() {
        return glossario[this.propLivroId];
    }
    handleListen(word) {
        this.listenCondition = true;
        this.listen = word;
        const utterance = new SpeechSynthesisUtterance(word);
        const livro = livroStore.livros.find((livroItem) => livroItem.id == this.propLivroId);
        utterance.lang = this.idiomas[livro.curso.idioma];
        window.speechSynthesis.speak(utterance);
        utterance.onend = () => {
            this.listenCondition = false;
            this.listen = '';
        };
    }
    startRecognition(name) {
        const normalizedName = name.toLowerCase().replace(/[^\w\s]/gi, '');
        if (this.recognition) {
            this.isMicAudio = name;
            this.recognition.lang = 'en-US';
            this.recognition.interimResults = false;
            this.recognition.maxAlternatives = 1;
            this.recognition.onstart = () => {
                this.isListening = true;
            };
            this.recognition.onresult = (event) => {
                const spokenWord = event.results[0][0].transcript
                    .trim()
                    .toLowerCase()
                    .replace(/[^\w\s]/gi, '');
                this.showSnackBar = true;
                if (spokenWord === normalizedName) {
                    this.isCorrect = true;
                    this.isText = 'Awesome!';
                }
                else {
                    this.isCorrect = false;
                    this.isText = 'Try again!';
                }
            };
            this.recognition.onend = () => {
                this.isListening = false;
            };
            this.recognition.onerror = (event) => {
                alert(`Erro de reconhecimento de fala: ${event.error}`);
                this.isListening = false;
            };
            this.recognition.start();
        }
        else {
            alert('Reconhecimento de fala não é suportado neste navegador');
        }
    }
    get filterItems() {
        if (this.tipoGlossario) {
            let item = this.items[this.tipoGlossario - 1].data;
            if (this.searchWords && item) {
                item = item.filter((f) => f.palavra.toLowerCase().includes(this.searchWords.toLowerCase()));
            }
            return item;
        }
        else {
            return [];
        }
    }
    handleItem(item) {
        this.tipoGlossario = item;
    }
    async mounted() {
        this.itemAudio = this.items[0];
        await livroStore.getLivros();
    }
};
__decorate([
    Prop({ type: Number, default: null })
], Glossary.prototype, "propLivroId", void 0);
Glossary = __decorate([
    Component({
        components: {
            FooterAudio,
        },
    })
], Glossary);
export default Glossary;
