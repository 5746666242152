import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Glossary from './Glossary.vue';
import Listening from './Listening.vue';
import Practice from './Practice.vue';
import { atHomeStore } from '@/utils/store-accessor';
let MinhaPratica = class MinhaPratica extends Vue {
    constructor() {
        super(...arguments);
        this.loading = null;
        this.id = null;
        this.livros = [];
        this.turma = null;
        this.tipoConteudo = 1; // audicao
    }
    onBack() {
        this.$router.back();
    }
    async mounted() {
        this.loading = true;
        this.id = this.$router.currentRoute.params.id;
        this.turma = await atHomeStore.getAtHomeTurma(this.id);
        // const response = await livroStore.getListening(this.livroCodigo);
        // this.livros = response.data;
        // this.livro = response.livro;
        // if (this.livros.length === 0) {
        //   this.$swal({
        //     title: `${
        //       // @ts-ignore
        //       this.palavrasTraduzidas[this.livro.curso.idioma].ouvindo
        //     } não encontrado`,
        //     text: 'Procure informações na recepção da sua unidade!',
        //     icon: 'warning',
        //     showCancelButton: false,
        //     confirmButtonText: 'Ok!',
        //   }).then(() => {
        //     // @ts-ignore
        //     this.$router.back();
        //   });
        // }
        this.loading = false;
    }
};
MinhaPratica = __decorate([
    Component({
        components: {
            Listening,
            Glossary,
            Practice,
        },
    })
], MinhaPratica);
export default MinhaPratica;
