import { __decorate } from "tslib";
import FooterAudio from '@/components/FooterAudio.vue';
import { songStore } from '@/store';
import { Component, Vue, Prop } from 'vue-property-decorator';
// @ts-ignore
import audicao from './audicao.json';
let Listening = class Listening extends Vue {
    get items() {
        return audicao[this.propLivroId];
    }
    playOrPause(track) {
        songStore.playOrPauseThisSong(track);
    }
    get openFooter() {
        return songStore.openFooter;
    }
    get isPlaying() {
        return songStore.isPlaying;
    }
    get currentTrack() {
        return songStore.currentTrack;
    }
    async mounted() {
        // @ts-ignore
        this.items.forEach((item) => {
            if (item.data) {
                item.data.forEach((track) => {
                    const audio = new Audio(`${track.url_audio}`);
                    audio.addEventListener('loadedmetadata', () => {
                        const duration = audio.duration;
                        const minutes = Math.floor(duration / 60);
                        const seconds = Math.floor(duration % 60);
                        this.$set(track, 'isTrackTime', `${minutes}:${seconds.toString().padStart(2, '0')}`);
                    });
                });
            }
        });
    }
};
__decorate([
    Prop({ type: Number, default: null })
], Listening.prototype, "propLivroId", void 0);
Listening = __decorate([
    Component({
        components: {
            FooterAudio,
        },
    })
], Listening);
export default Listening;
