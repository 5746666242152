import { __decorate } from "tslib";
import ShowDialog from '@/components/ShowDialog.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
// @ts-ignore
import pratica from './pratica.json';
let Practice = class Practice extends Vue {
    constructor() {
        super(...arguments);
        this.itemClick = null;
        this.subItem = {};
        this.page = 1;
        this.itemsPerPage = 1;
        this.condition = false;
        this.open = false;
        this.acertos = 0;
        this.totalPerguntas = 0;
    }
    get items() {
        // @ts-ignore
        return pratica[this.propLivroId];
    }
    handleYes(item1) {
        this.acertos = 0;
        item1.perguntas.forEach((pergunta) => {
            pergunta.respostas.forEach((answer) => {
                delete answer.color;
                delete answer.selected;
            });
        });
        this.open = false;
        this.itemClick = [];
        item1.dialog = false;
    }
    handleNo() {
        this.open = false;
    }
    handleNext(item) {
        if (this.page < this.subItem.length) {
            this.page++;
        }
        else if (this.page === this.subItem.length) {
            item.dialog = false;
            const nextItemInfo = this.nameNextItem;
            const form = this.$refs.showTestCompleted;
            form.show(item, nextItemInfo);
        }
    }
    get nameNextItem() {
        const currentIndex = this.items.findIndex((item) => item === this.itemClick);
        if (currentIndex !== -1 && currentIndex < this.items.length - 1) {
            const nextItem = this.items[currentIndex + 1];
            return {
                name: `<span class="articulat-regular">Você gostaria de iniciar o próximo teste</span> <b class="articulat-extrabold">${nextItem.teste}</b>?`,
                nameButton: 'PRÓXIMO TESTE',
            };
        }
        else {
            return { name: '', nameButton: 'Concluído' };
        }
    }
    nextTest() {
        this.acertos = 0;
        const currentIndex = this.items.findIndex((item) => item === this.itemClick);
        if (currentIndex !== -1 && currentIndex < this.items.length - 1) {
            this.itemClick = this.items[currentIndex + 1];
            const refs = this.$refs.showTestCompleted;
            refs.dialog = false;
            this.handleClick(this.itemClick);
        }
        else {
            this.closeTestConcluded();
        }
    }
    closeTestConcluded() {
        const refs = this.$refs.showTestCompleted;
        refs.dialog = false;
        this.itemClick = [];
    }
    selectAnswer(item, sub) {
        item.respostas.forEach((res) => {
            this.$set(res, 'selected', false);
        });
        this.$set(sub, 'selected', true);
        sub.color = sub.correta
            ? '1px solid #00B854'
            : '1px solid var(--v-redmain)';
        if (!sub.correta) {
            const correctAnswer = item.respostas.find((respostas) => respostas.correta);
            correctAnswer.color = '1px solid #00B854';
        }
        else {
            this.acertos += 1;
        }
    }
    handleColor(item1) {
        if (item1[0].respostas.some((i) => i.selected === true)) {
            this.condition = true;
            return true;
        }
        else {
            this.condition = false;
            return false;
        }
    }
    handleClick(item) {
        this.itemClick = item;
        item.dialog = true;
        this.subItem = item.perguntas;
        this.totalPerguntas = this.subItem.length;
        this.page = 1;
    }
    close() {
        this.open = true;
    }
    mounted() {
        this.itemClick = [];
    }
};
__decorate([
    Prop({ type: Number, default: null })
], Practice.prototype, "propLivroId", void 0);
Practice = __decorate([
    Component({
        components: {
            ShowDialog,
        },
    })
], Practice);
export default Practice;
